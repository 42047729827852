import {AxiosInstance} from 'axios';
import {generateUuid} from '../../util/helpers/generate-uuid';

class TrafficAnalyticsService {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async trackVisit(): Promise<void> {
    const utmParams = this.getUTMParams();
    if (!utmParams) {
      // If no utm params were parsed then don't track
      return;
    }

    const {clickId, utm} = utmParams;
    await this.sendEventToServer('visit', clickId, utm);
  }

  async trackClickRegister(): Promise<void> {
    const utmParams = this.getUTMParams();
    if (!utmParams) {
      // If no utm params were parsed then don't track
      return;
    }

    const {clickId} = utmParams;
    await this.sendEventToServer('click-register', clickId);
  }

  async trackStartSearch(): Promise<void> {
    await this.sendUserEventToServer('start-search');
  }

  async trackClickStartChat(): Promise<void> {
    await this.sendUserEventToServer('click-start-chat');
  }

  async trackOpenFinance(): Promise<void> {
    await this.sendUserEventToServer('open-finance');
  }

  async trackOpenAddFundsModal(): Promise<void> {
    await this.sendUserEventToServer('open-add-funds-modal');
  }

  async trackNavigateToDepositPage(): Promise<void> {
    await this.sendUserEventToServer('navigate-to-deposit-page');
  }

  private async sendEventToServer(eventName: any, clickId: string, utm?: Record<string, string>): Promise<void> {
    await this.httpClient.post<unknown, unknown, any>(
      '/traffic-analytics/track-event',
      {eventName, clickId, utm},
      {withCredentials: true}
    );
  }

  private async sendUserEventToServer(eventName: any): Promise<void> {
    await this.httpClient.post<unknown, unknown, any>('/traffic-analytics/track-user-event', {
      eventName,
    });
  }

  /* 
          Checks if user has clickId stored in cookie
        */
  private hasClickId(): boolean {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf('clickId=') === 0) {
        return true;
      }
    }
    return false;
  }

  getUTMParams(): any {
    // If there's no search param then quit right away
    if (!window.location.search) {
      return null;
    }

    const params = new URLSearchParams(window.location.search);
    const utm: Record<string, string> = {};

    // Iterate through params and build an object
    for (const [key, value] of params) {
      utm[key] = value;
    }

    // get or create clickId
    const clickId = params.get('clickId') ?? generateUuid();
    // add to the search params
    this.appendQueryParam('clickId', clickId);
    // remove from utms
    delete utm['clickId'];

    return {utm, clickId};
  }

  private appendQueryParam(key: string, value: string) {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    searchParams.set(key, value);
    currentUrl.search = searchParams.toString();
    window.history.pushState({path: currentUrl.href}, '', currentUrl.href);
  }
}

export default TrafficAnalyticsService;
