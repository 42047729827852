import {lazy} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {RenderWithSuspense} from './components/RenderWithSuspense';
import HomeWrapper from './pages/home-pages/home-wrapper';
import MainPage from './pages/home-pages/main-page';

// Home Lazy
const HomePage404 = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/page-404'));
const ContactUs = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/contact-us'));

export const router = (
  <Router>
    <Routes>
      <Route path="/" element={<HomeWrapper />}>
        <Route path="/login" element={<MainPage showLoginModalOnMount={true} />} />
        <Route index element={<MainPage showLoginModalOnMount={false} />} />
        <Route path="/redirect" element={<MainPage showLoginModalOnMount={false} />} />
        <Route
          path="contact-us"
          element={
            <RenderWithSuspense>
              <ContactUs />
            </RenderWithSuspense>
          }
        />
        <Route
          path="*"
          element={
            <RenderWithSuspense>
              <HomePage404 />
            </RenderWithSuspense>
          }
        />
      </Route>
    </Routes>
  </Router>
);
