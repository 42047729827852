import {lazy} from 'react';
import ActiveLink from './ActiveLink';
import {ReactComponent as IconAnonFun} from '../assets/svg/anonfun-logo-with-name.svg';
import {useModalState} from '../hooks/use-modal-state';
import {di} from '../dependency-injector';
import {RenderWithSuspense} from './RenderWithSuspense';

const LoginModal = lazy(() => import('./LoginModal/LoginModal'));

const MainHeader = () => {
  const {isOpen: isLoginModalOpen, show: showLoginModal, hide: hideLoginModal} = useModalState();
  const onJoinClick = () => {
    di.trafficAnalyticsService.trackClickRegister();
    showLoginModal();
  };
  return (
    <>
      <RenderWithSuspense>
        <LoginModal isOpen={isLoginModalOpen} hide={hideLoginModal} />
      </RenderWithSuspense>
      <div className="container mb-10 mt-20 flex w-full items-center justify-between lg:mb-20 lg:mt-25">
        <ActiveLink href="/">
          <div className={'flex flex-row gap-5'}>
            <IconAnonFun />
          </div>
        </ActiveLink>
        <button className="btn btn--sm btn--primary min-w-110" onClick={onJoinClick}>
          Log in
        </button>
      </div>
    </>
  );
};
export default MainHeader;
