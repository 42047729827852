import {useEffect, useState} from 'react';

export const useLazyLoad = (promise: PromiseLike<any>) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    promise.then((data) => {
      setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return data;
};
