import {memo, useMemo, lazy} from 'react';
import {useLazyLoad} from '../../../hooks/use-lazy-load';
import type {CardItem} from './HowDoesItWorkCarouselMobile';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useIsMobile} from '../../../hooks/use-is-mobile';
import {RenderWithSuspense} from '../../../components/RenderWithSuspense';
import {Skeleton} from '../../../ui/Skeleton';
import classNames from 'classnames';

const HowDoesItWorkDesktop = lazy(() => import(/* webpackPreload: true */ './HowDoesItWorkDesktop'));
const HowDoesItWorkCarouselMobile = lazy(() => import(/* webpackPreload: true */ './HowDoesItWorkCarouselMobile'));

interface Props {
  isCustomer: boolean;
}

const HowDoesItWork = memo((props: Props) => {
  const {isCustomer} = props;
  const isMobile = useIsMobile();
  const s1 = useLazyLoad(import('../../../assets/lottie-animations/s1.json'));
  const s2 = useLazyLoad(import('../../../assets/lottie-animations/s2.json'));
  const s3 = useLazyLoad(import('../../../assets/lottie-animations/s3.json'));
  const model2step = useLazyLoad(import('../../../assets/lottie-animations/model-2-step.json'));
  const model3step = useLazyLoad(import('../../../assets/lottie-animations/model-3-step.json'));
  const model4step = useLazyLoad(import('../../../assets/lottie-animations/model-4-step.json'));

  const items: CardItem[] = useMemo(() => {
    if (isCustomer) {
      return [
        {
          key: 'create-an-account',
          title: (
            <>
              Create <br className="hidden lg:block" /> an account
            </>
          ),
          animationSrc: s1,
        },
        {
          key: 'choose-model-you-like',
          title: (
            <>
              Choose the model <br className="hidden lg:block" /> you like
            </>
          ),
          animationSrc: s2,
        },
        {
          key: 'chat-any-buy-photos',
          title: (
            <>
              Chat and <br className="hidden lg:block" /> buy photos
            </>
          ),
          animationSrc: s3,
        },
      ];
    } else {
      return [
        {
          key: 'create-an-account',
          title: <>Create an account</>,
          animationSrc: s1,
        },
        {
          key: 'search-for-customer',
          title: <>Search for a customer</>,
          animationSrc: model2step,
        },
        {
          key: 'chat-any-send-photos',
          title: <>Chat and send photos</>,
          animationSrc: model3step,
        },
        {
          key: 'withdraw-earnings',
          title: <>Withdraw earnings</>,
          animationSrc: model4step,
        },
      ];
    }
  }, [isCustomer, s1, s2, s3, model2step, model3step, model4step]);

  return (
    <div className="container mt-40 lg:mt-50 xl:mt-85">
      <h2 className="mb-28 text-center text-[2.6rem] font-bold sm:text-2xl lg:mb-50 lg:text-left lg:text-3xl xl:mb-85 xl:text-4xl">
        How it works
      </h2>
      {isMobile ? (
        <RenderWithSuspense fallback={<HowDoesItWorkMobileSkeleton />}>
          <HowDoesItWorkCarouselMobile items={items} />
        </RenderWithSuspense>
      ) : (
        <RenderWithSuspense fallback={<HowDoesItWorkDesktopSkeleton items={items} isCustomer={isCustomer} />}>
          <HowDoesItWorkDesktop items={items} isCustomer={isCustomer} />
        </RenderWithSuspense>
      )}
    </div>
  );
});

const HowDoesItWorkMobileSkeleton = () => <Skeleton className={'aspect-square w-full rounded'} />;
const HowDoesItWorkDesktopSkeleton = ({isCustomer, items}: {isCustomer: boolean; items: CardItem[]}) => (
  <div
    className={classNames('grid gap-24', {
      'grid-cols-3 grid-rows-1': isCustomer,
      'grid-cols-2 grid-rows-2': !isCustomer,
    })}
  >
    {items.map((_, idx) => (
      <Skeleton key={idx} className={'h-500 rounded'} />
    ))}
  </div>
);

export default HowDoesItWork;
