import {memo, useEffect, lazy, useState} from 'react';
import {useModalState} from '../../../hooks/use-modal-state';
import {CustomerModelSwitcher} from './CustomerModelSwitcher';
import {di} from '../../../dependency-injector';
import {RenderWithSuspense} from '../../../components/RenderWithSuspense';
import HowDoesItWork from './HowDoesItWork';
import {useIsMobile} from '../../../hooks/use-is-mobile';

// Lazy load components
const WhyAnonFunBetter = lazy(() => import(/* webpackPrefetch: true */ './WhyAnonFunBetter'));
const SendPhotosBelowChin = lazy(() => import(/* webpackPrefetch: true */ './SendPhotosBelowChin'));
const MainFooter = lazy(() => import(/* webpackPrefetch: true */ './MainFooter'));
const LoginModal = lazy(() => import(/* webpackPrefetch: true */ '../../../components/LoginModal/LoginModal'));
const BackToTopButton = lazy(() => import(/* webpackPrefetch: true */ '../../../components/BackToTopButton'));

interface Props {
  showLoginModalOnMount: boolean;
}

export const Main = memo((props: Props) => {
  const {showLoginModalOnMount} = props;
  const {
    isOpen: isLoginModalOpen,
    show: showLoginModal,
    hide: hideLoginModal,
  } = useModalState({defaultValue: showLoginModalOnMount});
  const isMobile = useIsMobile();
  const [isCustomer, setIsCustomer] = useState(true);
  useEffect(() => {
    di.trafficAnalyticsService.trackVisit();
  }, []);
  const onJoinClick = () => {
    di.trafficAnalyticsService.trackClickRegister();
    showLoginModal();
  };
  return (
    <>
      <RenderWithSuspense>
        <LoginModal isOpen={isLoginModalOpen} hide={hideLoginModal} />
      </RenderWithSuspense>
      <div className="overflow-hidden">
        <div className="container grid grid-cols-1 justify-items-center text-center lg:grid-cols-2 lg:text-left">
          <div className={'grid auto-rows-auto lg:w-full'}>
            <h2 className="whitespace-nowrap pt-40 text-[2.4rem] font-bold sm:text-3xl lg:whitespace-normal lg:text-4xl">
              {isCustomer ? (
                <>
                  Chat with and purchase <br className={'block lg:hidden'} /> any photos from real{' '}
                  <br className={'block lg:hidden'} /> women
                </>
              ) : (
                <>
                  Explore a new way to <br className={'block lg:hidden'} /> earn money by selling{' '}
                  <br className={'block lg:hidden'} /> photos
                </>
              )}
            </h2>
            <div className="mb-20 mt-15 text-sm text-gray-dark sm:text-base lg:mb-52 lg:mt-28 lg:text-lg">
              {isCustomer ? (
                <>
                  Anonymous and safe. <br /> For you and for the model
                </>
              ) : (
                <>
                  Anonymous and safe. <br /> For you and for the customers
                </>
              )}
            </div>
            <CustomerModelSwitcher setIsCustomer={setIsCustomer} isCustomer={isCustomer} className={'lg:order-first'} />
            {isMobile && (
              <img
                src={`${process.env.PUBLIC_URL}/main-ill-mob.webp`}
                alt={'main-logo-mobile'}
                className={'mx-auto my-26 lg:hidden'}
                width={350}
                height={280}
              />
            )}
            <button className="btn btn--md btn--primary mx-auto w-100 lg:mx-0 lg:w-175" onClick={onJoinClick}>
              Join
            </button>
          </div>
          {!isMobile && (
            <img
              src={`${process.env.PUBLIC_URL}/main-ill-desktop.webp`}
              alt={'main-logo-desktop'}
              className={'hidden h-full w-full object-contain lg:block'}
            />
          )}
        </div>
      </div>
      <HowDoesItWork isCustomer={isCustomer} />
      <RenderWithSuspense>
        <SendPhotosBelowChin isCustomer={isCustomer} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <WhyAnonFunBetter isCustomer={isCustomer} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <MainFooter isCustomer={isCustomer} showLoginModal={onJoinClick} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <BackToTopButton />
      </RenderWithSuspense>
    </>
  );
});
