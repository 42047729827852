import TrafficAnalyticsService from './services/analytics-service/traffic-analytics-service';
import {api} from './http/api';

class DependencyInjector {
  private readonly _trafficAnalyticsService: TrafficAnalyticsService;

  constructor() {
    // Lock manager
    // Traffic analytics service
    this._trafficAnalyticsService = new TrafficAnalyticsService(api);
  }

  get trafficAnalyticsService() {
    return this._trafficAnalyticsService;
  }
}

export const di = new DependencyInjector();
